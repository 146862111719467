import GraphQlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [GraphQlMixin, FormattingMixin],
  data() {
    return {
      statuses: [
        {
          value: 'WAITING_FOR_MANAGER',
          text: 'Waiting For Manager',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'mobileDeployments',
            'mobileReturns',
            'mobileSimChanges',
            'mobileUserChanges',
            'pcAccessories',
            'pcOsRefreshes',
            'pcRepairs',
            'pcUserChanges',
            'pcUserChangesOnly'
          ]
        },
        {
          value: 'ECC_MANAGER_APPROVAL',
          text: 'ECC Manager Approval',
          workflowTicketTypes: ['mobileDeployments']
        },
        {
          value: 'CITSD_APPROVAL',
          text: 'CITSD Approval',
          workflowTicketTypes: ['mobileDeployments']
        },
        {
          value: 'CEO_OFFICE_APPROVAL',
          text: 'CEO Office Approval',
          workflowTicketTypes: ['mobileDeployments']
        },
        {
          value: 'CONFIRMING',
          text: 'Confirming',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'mobileDeployments',
            'mobileSimChanges',
            'mobileUserChanges',
            'mobileReturns',
            'pcAccessories',
            'pcOsRefreshes',
            'pcRepairs',
            'pcReturns',
            'pcUserChanges',
            'pcUserChangesOnly'
          ]
        },
        // // add static value to prevent staging fails
        // {
        //   value: 'PREDEPLOYMENT_VALIDATION',
        //   text: 'Pre-Deployment Validation',
        //   workflowTicketTypes: [
        //     'allPcTickets',
        //     'allMobileTickets',
        //     'desktopDeployments',
        //     'laptopDeployments',
        //     'mobileDeployments',
        //     'mobileSimChanges',
        //     'mobileUserChanges',
        //     'mobileReturns',
        //     'pcAccessories',
        //     'pcOsRefreshes',
        //     'pcRepairs',
        //     'pcReturns',
        //     'pcUserChanges',
        //     'pcUserChangesOnly'
        //   ]
        // },
        {
          value: 'PROCESSING',
          text: 'Processing',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'pcAccessories'
          ]
        },
        {
          value: 'ORDERING_SIM',
          text: 'Ordering Sim',
          workflowTicketTypes: [
            'allMobileTickets',
            'mobileDeployments',
            'mobileUserChanges',
            'mobileSimChanges'
          ]
        },
        {
          value: 'REPAIRING',
          text: 'Repairing',
          workflowTicketTypes: ['allPcTickets', 'pcRepairs']
        },
        {
          value: 'WAITING_USER_TO_HAND_OVER_DEVICE',
          text: 'Waiting User To Hand Over Device',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'pcOsRefreshes',
            'pcUserChanges',
            'pcUserChangesOnly',
            'mobileDeployments',
            'mobileUserChanges',
            'mobileSimChanges',
            'mobileReturns'
          ]
        },
        {
          value: 'SET_UP',
          text: 'Set Up',
          workflowTicketTypes: [
            'allMobileTickets',
            'mobileDeployments',
            'mobileUserChanges',
            'mobileSimChanges'
          ]
        },
        {
          value: 'REFRESHING',
          text: 'Refreshing',
          workflowTicketTypes: [
            'allPcTickets',
            'pcUserChanges',
            'pcOsRefreshes'
          ]
        },
        {
          value: 'READY_FOR_DEPLOY',
          text: 'Ready For Deploy',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'pcOsRefreshes',
            'pcUserChanges',
            'pcUserChangesOnly',
            'pcRepairs',
            'pcAccessories',
            'mobileDeployments',
            'mobileUserChanges',
            'mobileSimChanges'
          ]
        },
        {
          value: 'REQUEST_FOR_TERMINATING_SIM',
          text: 'Request For Terminating Sim',
          workflowTicketTypes: ['allMobileTickets', 'mobileReturns']
        },
        {
          value: 'COMPLETED',
          text: 'Completed',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'mobileDeployments',
            'mobileReturns',
            'mobileSimChanges',
            'mobileUserChanges',
            'pcAccessories',
            'pcOsRefreshes',
            'pcRepairs',
            'pcReturns',
            'pcUserChanges',
            'pcUserChangesOnly'
          ]
        },
        {
          value: 'REJECTED',
          text: 'Rejected',
          workflowTicketTypes: [
            'allPcTickets',
            'allMobileTickets',
            'desktopDeployments',
            'laptopDeployments',
            'mobileDeployments',
            'mobileReturns',
            'mobileSimChanges',
            'mobileUserChanges',
            'pcAccessories',
            'pcOsRefreshes',
            'pcRepairs',
            'pcReturns',
            'pcUserChanges',
            'pcUserChangesOnly'
          ]
        }
      ]
    }
  },
  computed: {
    currentType() {
      if (this.workflowTicketGraphType) {
        return (
          this.$store.getters['graphql/pcWorkflowTicketTypes'].find(
            (x) => x.graphTypeName === this.workflowTicketGraphType
          ) ||
          this.$store.getters['graphql/mobileWorkflowTicketTypes'].find(
            (x) => x.graphTypeName === this.workflowTicketGraphType
          )
        )
      } else if (
        this.$store.getters['graphql/workflowTicketType'].graphTypeName ===
        'PcWorkflowTicket'
      ) {
        return this.$store.getters['graphql/pcWorkflowTicketType']
      } else {
        return this.$store.getters['graphql/mobileWorkflowTicketType']
      }
    },
    extraFields() {
      return this.currentType.fields.filter((f) => f.extra)
    },
    ...mapGetters('graphql', ['workflowTicketType']),
    ...mapGetters('graphql', ['pcWorkflowTicketType']),
    ...mapGetters('graphql', ['mobileWorkflowTicketType'])
  },
  methods: {
    getFieldLabel(name) {
      return _.get(
        _.find(this.currentType.fields, (x) => x.name === name),
        'displayName',
        _.startCase(name)
      )
    },
    getFilterFieldType(f) {
      return _.replace(_.get(f, 'field.value.type'), /!/g, '')
    },
    filterToWhereExpression(root) {
      if (_.isEmpty(root) || _.isEmpty(root.filters)) {
        return []
      }
      return [this.filterGroupToWhere(root, root.connector)]
    },
    filterToWhere(filter, connector) {
      let where = {
        path: _.get(filter, 'field.value.name'),
        comparison: _.get(filter, 'operator.value'),
        value: '',
        negate: filter.negate,
        connector
      }
      if (
        typeof filter.value === 'object' ||
        typeof filter.value === 'undefined'
      ) {
        if (filter.field.value.type === 'Boolean') {
          where.path = `${filter.field.value.name}`
          where.value = filter.value.value
        } else {
          let ids = []
          let names = []
          let selections = _.castArray(filter.value)
          for (let item of selections) {
            if (_.get(item, 'id')) {
              ids.push(item.id)
            } else if (
              typeof item === 'string' ||
              item === null ||
              item === undefined
            ) {
              if (item === null && _.includes(filter.field.type, 'String')) {
                item = ''
              }
              names.push(item)
            }
          }
          if (ids.length > 0) {
            where.path = `${filter.field.value.name}.id`
            where.value = ids
          } else if (names.length > 0) {
            where.value = names
          }
        }
      } else {
        switch (filter.operator.value) {
          case 'equal':
          case 'greaterThan':
          case 'greaterThanOrEqual':
          case 'lessThan':
          case 'lessThanOrEqual':
          case 'contains':
          case 'startsWith':
          case 'endsWith':
            where.value = [filter.value]
            break
          case 'in':
            break
          case 'like':
            break
          case 'empty':
            break
          default:
            break
        }
      }
      return where
    },
    filterGroupToWhere(group, connector) {
      const where = {
        groupedExpressions: [],
        negate: group.negate,
        connector
      }
      for (let filter of group.filters) {
        if (filter.group) {
          where.groupedExpressions.push(
            this.filterGroupToWhere(filter, group.connector)
          )
        } else {
          where.groupedExpressions.push(
            this.filterToWhere(filter, group.connector)
          )
        }
      }
      return where
    },
    getWorkflowTicketQuery() {
      const allFields = [
        'id',
        'externalId',
        'name',
        'location',
        'memo',
        'lastUpdate',
        'applicant',
        'applicantEmployeeId',
        'creator',
        'status',
        'statusBeforeReject',
        'remarks',
        'fileAttachments',
        'costCode',
        'requestedDeliveryDate',
        'aplikoStatus',
        'isCompleted',
        'workflowTicketType',
        'personInCharge',
        'rejectReason'
      ]
        .concat(this.extraFields.filter((f) => !f.fields).map((f) => f.name))
        .concat(this.extraFields.filter((f) => f.fields))

      const fields = this.getFieldsGql(
        'read',
        this.currentType.graphTypeName,
        allFields
      )

      return this.$gql`
        query workflowTicket($id: ID!) {
          ${this.currentType.singleQueryName}(id: $id) {
            ${fields}
          }
        }
      `
    }
  }
}
