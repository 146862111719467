import GraphQlMixin from '@/mixins/graphql.mixin'

export default {
  props: {
    keyword: String,
    selectedUser: Object,
    isReadOnly: Boolean,
    rules: Array
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      users: []
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selectedUser() {
      this.updateSelectedUser()
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', _.get(o, 'samAccountName'))
        this.$emit('update:selectedUser', o)
      } else {
        this.$emit('update:keyword', '')
        this.$emit('update:selectedUser', null)
      }
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    query() {
      const fields = this.getFieldsGql('read', 'IntraAccount', ['id', 'name', 'samAccountName', 'employeeId'])
        return this.$gql`
          query SearchIntraAccounts($q: String!, $take: Int)
          {
            intraAccounts(search: $q, take: $take)  {
              items {
                ${fields}
              }
            }
          }
        `
    }
  },
  methods: {
    updateSelectedUser() {
      if (this.selectedUser !== null) {
        this.users.push(this.selectedUser)
        this.users = _.uniqBy(this.users, 'id')
        this.select = this.selectedUser
      }
    }
  },
  apollo: {
    users: {
      query() {
        return this.query
      },
      variables() {
        return { q: this.search, take: 10 }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ intraAccounts }) {
        intraAccounts = _.get(intraAccounts, 'items', [])
        let result = [..._.toArray(intraAccounts)]
        if (!_.isEmpty(this.select)) {
          result.push(this.select)
        } else {
          let match = result.find(x => x.name === this.search)
          this.select = match
        }
        return result
      },
      result({ error }) {
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelectedUser()
  }
}