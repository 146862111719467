import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'

export default {
  props: {
    workflowTicket: { type: Object, default: () => {} },
    workflowTicketGraphType: { type: String, default: null }
  },
  mixins: [WorkflowTicketMixin],
  data() {
    return {
      fields: []
    }
  },
  watch: {
    workflowTicket() {
      this.fields = []
      this.setFields()
    }
  },
  methods: {
    setFields() {
      for (let extraField of this.extraFields.filter(f => f.name && f.readOnly)) {
        var fieldNameProperCase = _.upperFirst(extraField.name)
        this.fields.push({
          value: this.workflowTicket.extraFields[extraField.name],
          hasPermission: this.ability.can('read', this.currentType.graphTypeName, extraField.name) || this.ability.can('read', this.currentType.graphTypeName, fieldNameProperCase),
          ...extraField
        })
      }
    },
    getFieldDisplayName(f) {
      return f.displayName || _.startCase(f.name)
    }
  }
}
