import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import GetWorkflowTicketHistories from '@/graphql/GetWorkflowTicketHistories.gql'

export default {
  props: ['id'],
  mixins: [WorkflowTicketMixin],
  data() {
    return {
      histories: [],
      loading: true,
      headers: [
        {
          text: 'Date / Action / By',
          value: 'createdAt'
        },
        {
          text: 'Fields',
          value: 'fields',
          sortable: false
        },
        {
          text: 'Old value',
          value: 'old',
          sortable: false
        },
        {
          text: 'New value',
          value: 'new',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    histories: {
      query: GetWorkflowTicketHistories,
      variables() {
        return {
          id: parseInt(this.id)
        }
      },
      update: ({ workflowTicket }) => workflowTicket.histories,
      result({ data, error }) {
        if (error) {
          this.graphQLOnError(`Failed to get histories. ${error.toString()}`)
        } else {
          for (let item of data.workflowTicket.histories) {
            if (item.changes) {
              for (let change of item.changes) {
                change.field = _.startCase(
                  change.key.replaceAll(/\[(.+)\]|Id/g, '')
                )

                const fieldmeta = this.currentType.fields.find(
                  (f) => f.name.toLowerCase() === change.key.toLowerCase()
                )
                if (fieldmeta && fieldmeta.type) {
                  switch (fieldmeta.type.replace('!', '')) {
                    case 'Date':
                      change.old = this.formatDisplayDate(change.old)
                      change.new = this.formatDisplayDate(change.new)
                      break
                    case 'DateTimeOffset':
                      change.old = this.formatDisplayDateTime(change.old)
                      change.new = this.formatDisplayDateTime(change.new)
                      break
                    case 'AssetStatus':
                      change.old = _.get(
                        this.statusMap,
                        `[${change.old}].text`,
                        change.old
                      )
                      change.new = _.get(
                        this.statusMap,
                        `[${change.new}].text`,
                        change.new
                      )
                    default:
                      break
                  }
                }
                this.setItemPath(change.oldItem)
                this.setItemPath(change.newItem)
              }
            }
          }
        }
      },
      skip() {
        return !this.id
      }
    }
  },
  methods: {
    setItemPath(item) {
      if (_.get(item, '__typename')) {
        switch (item.__typename) {
          case 'IntraAccount':
            item.path = 'show-user'
            break
          default:
            item.path = null
        }
      }
    }
  }
}
