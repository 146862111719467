import GraphQlMixin from '@/mixins/graphql.mixin'

export default {
  props: {
    keyword: String,
    selectedComputer: Object,
    isReadOnly: Boolean,
    rules: Array
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      computers: []
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selectedComputer() {
      this.updateSelectedComputer()
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', _.get(o, 'name'))
        this.$emit('update:selectedComputer', o)
      } else {
        this.$emit('update:keyword', '')
        this.$emit('update:selectedComputer', null)
      }
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    query() {
      const fields = this.getFieldsGql('read', 'Computer', [
        'id',
        'name',
        'alternativeName',
        'serialNumber',
        {
          name: 'model',
          type: 'Model',
          fields: [
            'id',
            'name',
            {
              name: 'manufacturer',
              type: 'Manufacturer',
              fields: ['id', 'name']
            }
          ]
        }
      ])
      return this.$gql`
          query SearchComputers($q: String!, $take: Int, $where: [WhereExpression])
          {
            computers(search: $q, take: $take, where: $where)  {
              items {
                ${fields}
              }
            }
          }
        `
    }
  },
  methods: {
    updateSelectedComputer() {
      if (this.selectedComputer !== null) {
        this.computers.push(this.selectedComputer)
        this.computers = _.uniqBy(this.computers, 'id')
        this.select = this.selectedComputer
      }
    }
  },
  apollo: {
    computers: {
      query() {
        return this.query
      },
      variables() {
        return {
          q: this.search,
          take: 10,
          where: [
            {
              path: 'status',
              comparison: 'equal',
              value: 'DECOMMISSIONED',
              negate: true
            },
            {
              path: 'isDeleted',
              comparison: 'equal',
              value: 'false',
              negate: false
            }
          ]
        }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ computers }) {
        computers = _.get(computers, 'items', [])
        let result = [..._.toArray(computers)]
        if (!_.isEmpty(this.select)) {
          result.push(this.select)
        } else {
          let match = result.find((x) => x.name === this.search)
          this.select = match
        }
        return result
      },
      result({ error }) {
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelectedComputer()
  }
}
