import GraphQlMixin from '@/mixins/graphql.mixin'

export default {
  props: {
    keyword: String,
    selectedMobile: Object,
    isReadOnly: Boolean,
    rules: Array
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      mobiles: []
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selectedMobile() {
      this.updateSelectedMobile()
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', _.get(o, 'name'))
        this.$emit('update:selectedMobile', o)
      } else {
        this.$emit('update:keyword', '')
        this.$emit('update:selectedMobile', null)
      }
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    query() {
      const fields = this.getFieldsGql('read', 'Mobile', [
        'id',
        'name',
        'serialNumber',
        {
          name: 'model',
          type: 'Model',
          fields: [
            'name',
            {
              name: 'manufacturer',
              type: 'Manufacturer',
              fields: ['id', 'name']
            }
          ]
        },
        {
          name: 'simSlots',
          type: 'SimSlot',
          fields: [
            'iMEI',
            {
              name: 'sim',
              type: 'Sim',
              fields: ['id', 'name', 'serialNumber',
                {
                  name: 'model',
                  type: 'Model',
                  fields: [
                    'name',
                    {
                      name: 'manufacturer',
                      type: 'Manufacturer',
                      fields: ['id', 'name']
                    }
                  ]
                }
              ]
            }
          ]
        }
      ])
      return this.$gql`
          query SearchMobiles($q: String!, $take: Int)
          {
            mobiles(search: $q, take: $take)  {
              items {
                ${fields}
              }
            }
          }
        `
    }
  },
  methods: {
    updateSelectedMobile() {
      if (this.selectedMobile !== null) {
        this.mobiles.push(this.selectedMobile)
        this.mobiles = _.uniqBy(this.mobiles, 'id')
        this.select = this.selectedMobile
      }
    }
  },
  apollo: {
    mobiles: {
      query() {
        return this.query
      },
      variables() {
        return {
          q: this.search,
          take: 10,
          where: [
            {
              path: 'status',
              comparison: 'equal',
              value: 'DECOMMISSIONED',
              negate: true
            },
            {
              path: 'isDeleted',
              comparison: 'equal',
              value: 'false',
              negate: false
            }
          ]
        }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ mobiles }) {
        mobiles = _.get(mobiles, 'items', [])
        let result = [..._.toArray(mobiles)]
        if (!_.isEmpty(this.select)) {
          result.push(this.select)
        } else {
          let match = result.find((x) => x.name === this.search)
          this.select = match
        }
        return result
      },
      result({ error }) {
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelectedMobile()
  }
}
