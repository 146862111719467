import GraphQlMixin from '@/mixins/graphql.mixin'

export default {
  props: {
    keyword: String,
    selectedSim: Object,
    isDisabled: Boolean,
    isReadOnly: Boolean,
    rules: Array
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      sims: []
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selectedSim() {
      this.updateSelectedSim()
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', _.get(o, 'name'))
        this.$emit('update:selectedSim', o)
      } else {
        this.$emit('update:keyword', '')
        this.$emit('update:selectedSim', null)
      }
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    query() {
      const fields = this.getFieldsGql('read', 'Sim', [
        'id',
        'name',
        'serialNumber',
        {
          name: 'model',
          type: 'Model',
          fields: [
            'name',
            {
              name: 'manufacturer',
              type: 'Manufacturer',
              fields: ['id', 'name']
            }
          ]
        }
      ])
      return this.$gql`
          query SearchSims($q: String!, $take: Int, $where: [WhereExpression])
          {
            sims(search: $q, take: $take, where: $where)  {
              items {
                ${fields}
              }
            }
          }
        `
    }
  },
  methods: {
    updateSelectedSim() {
      if (this.selectedSim !== null) {
        this.sims.push(this.selectedSim)
        this.sims = _.uniqBy(this.sims, 'id')
        this.select = this.selectedSim
      }
      else {
        this.select = null
      }
    }
  },
  apollo: {
    sims: {
      query() {
        return this.query
      },
      variables() {
        return {
          q: this.search,
          take: 10,
          where: [
            {
              path: 'status',
              comparison: 'equal',
              value: 'In Stock',
              negate: false
            },
            {
              path: 'simSlot',
              comparison: 'equal',
              value: null,
              negate: false
            },
            {
              path: 'isDeleted',
              comparison: 'equal',
              value: 'false',
              negate: false
            }
          ]
        }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ sims }) {
        sims = _.get(sims, 'items', [])
        let result = [..._.toArray(sims)]
        if (!_.isEmpty(this.select)) {
          result.push(this.select)
        } else {
          let match = result.find((x) => x.name === this.search)
          this.select = match
        }
        return result
      },
      result({ error }) {
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelectedSim()
  }
}
