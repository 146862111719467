import MailTemplateForm from '@/components/MailTemplateForm/MailTemplateForm.vue'
import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import { mapMutations } from 'vuex'

export default {
  props: {
    mailTemplateVariables: { type: Object, default: () => ({}) },
    workflowId: { type: String, default: null },
    workflowTicketGraphType: { type: String, default: null}
  },
  components: {
    MailTemplateForm
  },
  mixins: [WorkflowTicketMixin],
  data() {
    return {
      mailTemplates: [],
      mailTemplateId: null,
      notification: null,
      loading: false,
      confirmNotifyDialog: false,
      disableSend: false
    }
  },
  apollo: {
    mailTemplates: {
      query() {
        const fields = this.getFieldsGql('read', 'MailTemplate', ['id', 'name'])

        return this.$gql`
          query mailTemplates {
            mailTemplates(where: [{ path: "mailTemplateSubType", comparison:in, value: [
              "${this.currentType.mailTemplateTypeName}"
              ]}], orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      result({ data, error }) {
        if (data && data.mailTemplates) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data.mailTemplates.items.length; i++) {
              if (data.mailTemplates.items[i].id === this.updatedItem.id) {
                data.mailTemplates.items[i] = this.updatedItem
                break
              }
            }
          }
          this.mailTemplates = data.mailTemplates.items
          if (_.isEmpty(this.mailTemplates)) {
            this.setAppSnackbar({
              text: `There's no mail templates configured for ${this.workflowTicketGraphType}`,
              color: 'error'
            })
          } else {
            this.mailTemplateId = this.mailTemplates[0].id
          }
        } else if (error) {
          this.setAppSnackbar({
            text: `Failed to get list of mail templates. ${error.toString()}`,
            color: 'error'
          })
        }
      }
    }
  },
  methods: {
    getNotification(v) {
      if (v === null) {
        this.disableSend = true
      } else if (v.to.length < 1 || v.subject.length < 1) {
        this.disableSend = true
      } else {
        this.disableSend = false
      }
      this.notification = v
    },
    notify() {
      this.confirmNotifyDialog = false
      this.loading = true

      let input = {
        from: this.notification.from,
        subject: this.notification.subject,
        body: this.notification.body,
        tos: this.notification.to,
        ccs: this.notification.cc,
        bccs: this.notification.bcc,
        itemId: this.workflowId,
        itemType: 'Workflow'
      }

      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
            mutation workflowNotify($input: NotifyInput!) {
              workflowNotify(input: $input)
            }
          `,
          variables: { input }
        })
        .then(() => {
          this.setAppSnackbar({
            text: 'Sent notification successfully.',
            color: 'success'
          })
        })
        .catch((error) => {
          this.setAppSnackbar({
            text: `Failed to send notification. ${error}`,
            color: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapMutations(['setAppSnackbar'])
  }
}
