import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import UserSearcher from '@/components/inputs/UserSearcher/UserSearcher.vue'
import ComputerSearcher from '@/components/inputs/ComputerSearcher/ComputerSearcher.vue'
import MobileSearcher from '@/components/inputs/MobileSearcher/MobileSearcher.vue'
import SimSearcher from '@/components/inputs/SimSearcher/SimSearcher.vue'

const requiredRule = (v) => !!v || 'Required'

export default {
  props: [
    'workflowTicket',
    'extraField',
    'isReadOnly',
    'validateWorkflowTicketForm'
  ],
  mixins: [WorkflowTicketMixin],
  components: {
    UserSearcher,
    ComputerSearcher,
    MobileSearcher,
    SimSearcher
  },
  data() {
    return {
      submitting: false,
      field: {
        name: null,
        value: null,
        rules: []
      },
      input: ''
    }
  },
  watch: {
    workflowTicket: {
      deep: true,
      handler(v) {
        this.field.value = this.workflowTicket.extraFields[this.extraField.name]
      }
    },
    'field.value': {
      deep: true,
      handler(value) {
        this.workflowTicket.extraFields[this.extraField.name] = value

        if (
          this.extraField.name === 'operatorMobile' &&
          Object.keys(this.workflowTicket.extraFields).includes(
            'operatorSim'
          ) &&
          this.workflowTicket.extraFields['operatorSim'] === null
        ) {
          this.workflowTicket.extraFields['operatorSim'] = _.get(
            value,
            'simSlots[0].sim',
            null
          )
        }

        if (
          this.extraField.name === 'operatorMobile' &&
          Object.keys(this.workflowTicket.extraFields).includes(
            'operatorOldSim'
          )
        ) {
          this.workflowTicket.extraFields['operatorOldSim'] = _.get(
            value,
            'simSlots[0].sim',
            null
          )
        }
        this.updateWorkflowTicket(this.workflowTicket)
      }
    }
  },
  methods: {
    getFieldDisplayName(f) {
      return f.displayName || _.startCase(f.name)
    },
    updateWorkflowTicket(workflowTicket) {
      this.$emit('updateWorkflowTicket', workflowTicket)
    }
  },
  created() {
    if (
      this.extraField.name === 'operatorComputer' ||
      this.extraField.name === 'operatorMobile' ||
      this.extraField.name === 'operatorSim' ||
      this.extraField.name === 'operatorNewSim' ||
      this.extraField.name === 'operatorOldSim'
    ) {
      this.field.value = this.workflowTicket.extraFields[this.extraField.name]
      if (this.field.value !== null && this.field.value !== undefined) {
        this.input = _.get(
          this.workflowTicket.extraFields[this.extraField.name],
          'name'
        )
      } else {
        if (this.extraField.name === 'operatorSim') {
          if (
            this.workflowTicket.extraFields['operatorMobile'] &&
            this.workflowTicket.extraFields['operatorMobile'].simSlots
          ) {
            this.field.value =
              this.workflowTicket.extraFields['operatorMobile'].simSlots[0].sim
          }
        }

        if (this.extraField.name === 'operatorOldSim') {
          if (
            this.workflowTicket.extraFields['operatorMobile'] &&
            this.workflowTicket.extraFields['operatorMobile'].simSlots
          ) {
            this.field.value =
              this.workflowTicket.extraFields['operatorMobile'].simSlots[0].sim
          }
        }
      }
    } else if (this.extraField.name === 'operatorAssignee') {
      this.field.value = this.workflowTicket.extraFields[this.extraField.name]
      if (this.field.value !== null && this.field.value !== undefined) {
        this.input = _.get(
          this.workflowTicket.extraFields[this.extraField.name],
          'samAccountName'
        )
      }
    } else {
      this.field.value = this.workflowTicket.extraFields[this.extraField.name]
      this.input = this.field.value
    }

    if (this.validateWorkflowTicketForm) {
      switch (this.extraField.name) {
        case 'operatorSim':
          if (
            _.get(this.workflowTicket, 'name', '').indexOf('PCL002') === 0 &&
            _.get(this.workflowTicket, 'extraFields.os', '') !== 'IOS'
          ) {
            this.field.rules = []
          }
          else {
            this.field.rules = [requiredRule]
          }
          break
        default:
          this.field.rules = [requiredRule]
          break
      }
    }
  }
}
