import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import WorkflowTicketExtraFields from '@/components/Workflow/WorkflowTicketExtraFields/WorkflowTicketExtraFields.vue'
import WorkflowTicketHistory from '@/components/Workflow/WorkflowTicketHistory/WorkflowTicketHistory.vue'
import WorkflowTicketMailTemplate from '@/components/Workflow/WorkflowTicketMailTemplate/WorkflowTicketMailTemplate.vue'
import WorkflowTicketAction from '@/components/Workflow/WorkflowTicketAction/WorkflowTicketAction.vue'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import getEnv from '@/utils/env'

export default {
  props: {
    id: { type: String, default: null },
    workflowTicketGraphType: { type: String, default: null }
  },
  mixins: [WorkflowTicketMixin, FormattingMixin],
  components: {
    FullScreenLoader,
    WorkflowTicketExtraFields,
    WorkflowTicketHistory,
    WorkflowTicketMailTemplate,
    WorkflowTicketAction
  },
  data() {
    return {
      workflowTicket: {},
      bulkApplicationNumber: null
    }
  },
  watch: {
    workflowTicket(v) {
      this.bulkApplicationNumber = v.extraFields['bulkApplicationNumber']
    }
  },
  computed: {
    rawsExternalLink() {
      return _.startsWith(this.workflowTicket.name, 'PCL')
        ? `${getEnv('VUE_APP_RAWS_BASE_URL')}/${this.currentType.rawsId}/${
            this.workflowTicket.externalId
          }/view`
        : `${getEnv('VUE_APP_APLIKO_BASE_URL')}/aqua/${
            this.workflowTicket.externalId
          }/view`
    },
    mailTemplateVariables() {
      // Common attributes
      let variables = {
        WFID: _.trim(_.get(this.workflowTicket, 'name', '')),
        WFType: _.trim(_.get(this.workflowTicket, 'discriminator', '')),
        ApplicantUser: _.trim(_.get(this.workflowTicket, 'applicant', '')),
        CreatorUser: _.trim(_.get(this.workflowTicket, 'creator', '')),
        User: _.trim(_.get(this.workflowTicket, 'applicant', '')),
        NotificationAdEmails: _.trim(_.get(this.workflowTicket, 'extraFields.notificationAdEmails', '')),

        // PC
        AssetNo: _.trim(_.get(this.workflowTicket, 'extraFields.operatorComputer.name', '')),
        AssetHostname: _.trim(_.get(this.workflowTicket, 'extraFields.operatorComputer.name', '')),
        ApplyForUser: _.trim(_.get(this.workflowTicket, 'extraFields.applyForUsername', '')),
        AssigneeSamAccountName: _.trim(_.get(this.workflowTicket, 'extraFields.operatorAssignee.samAccountName', '')),
        AssigneeEmployeeId: _.trim(_.get(this.workflowTicket, 'extraFields.operatorAssignee.employeeId', '')),
        
        // PcAccessory
        ProductName: _.trim(_.get(this.workflowTicket, 'extraFields.productName', '')),
        ProductUnit: _.trim(_.get(this.workflowTicket, 'extraFields.units', '')),

        // Mobile
        DeviceName: _.trim(_.get(this.workflowTicket, 'deviceName', ''))
      }

      return variables
    }
  },
  apollo: {
    workflowTicket: {
      query() {
        return this.getWorkflowTicketQuery()
      },
      variables() {
        return { id: parseInt(this.id) }
      },
      skip() {
        return !this.id
      },
      update(data) {
        const workflowTicket = data[this.currentType.singleQueryName]

        this.workflowTicket = {
          ...this.workflowTicket,
          ...{
            id: workflowTicket.id,
            externalId: workflowTicket.externalId,
            name: workflowTicket.name,
            applicant: workflowTicket.applicant,
            applicantEmployeeId: workflowTicket.applicantEmployeeId,
            creator: workflowTicket.creator,
            location: workflowTicket.location,
            requestedDeliveryDate: workflowTicket.requestedDeliveryDate,
            status: workflowTicket.status,
            statusBeforeReject: workflowTicket.statusBeforeReject,
            memo: workflowTicket.memo,
            aplikoStatus: workflowTicket.aplikoStatus,
            isCompleted: workflowTicket.isCompleted,
            remarks: workflowTicket.remarks,
            fileAttachments: workflowTicket.fileAttachments,
            personInCharge: workflowTicket.personInCharge,
            rejectReason: workflowTicket.rejectReason,
            extraFields: {}
          }
        }

        for (let ef of this.extraFields) {
          this.$set(
            this.workflowTicket.extraFields,
            ef.name,
            workflowTicket[ef.name]
          )
        }

        this.updateWorkflowTicket(this.workflowTicket)
        return this.workflowTicket
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(
            `Failed to get workflow ticket data from server. ${error.toString()}`
          )
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        }
      }
    }
  },
  methods: {
    updateWorkflowTicket(workflowTicket) {
      this.$emit('updateWorkflowTicket', workflowTicket)
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
