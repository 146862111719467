import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import { mapMutations } from 'vuex'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import WorkflowTicketForm from '@/components/Workflow/WorkflowTicketForm/WorkflowTicketForm.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    workflowTicket: { type: Object, default: () => {} },
    workflowTicketGraphType: { type: String, default: null },
    isParentLoading: { type: Boolean }
  },
  mixins: [WorkflowTicketMixin],
  components: {
    WorkflowTicketForm,
    FullScreenLoader
  },
  data() {
    return {
      loading: false,
      comment: '',
      computer: {},
      mobile: {},
      newSim: {},
      confirmActionDialog: false,
      currentAction: '',
      isFormValid: true
    }
  },
  computed: {
    currentTicketStatuses() {
      switch (_.get(this.workflowTicket, 'extraFields.deviceModel')) {
        case 'Travel':
          return this.statuses.filter((x) => x.value != 'ECC_MANAGER_APPROVAL' && x.value != 'CITSD_APPROVAL')
        case 'Others':
          if (_.get(this.workflowTicket, 'extraFields.simType') === 'Other Carriers') {
            return this.statuses.filter((x) => x.value != 'ECC_MANAGER_APPROVAL')
          } else {
            return this.statuses.filter((x) => x.value != 'ECC_MANAGER_APPROVAL' && x.value != 'CITSD_APPROVAL')
          }
        case 'System Monitoring Device':
          return this.statuses.filter((x) => x.value != 'ECC_MANAGER_APPROVAL' && x.value != 'CEO_OFFICE_APPROVAL' && x.value != 'CITSD_APPROVAL')
        case 'App Dev':
          return this.statuses.filter((x) => x.value != 'ECC_MANAGER_APPROVAL')
        default:
          return this.statuses.filter((x) => x.value != 'CEO_OFFICE_APPROVAL' && x.value != 'CITSD_APPROVAL')
      }
    },
    rejectedStatuses() {
      let filteredStatuses = this.currentTicketStatuses.filter(
        (x) =>
          x.workflowTicketTypes.includes(this.currentType.listQueryName) &&
          x.value !== 'COMPLETED' &&
          x.value !== 'CLOSED'
      )
      let statusBeforeReject = this.workflowTicket.statusBeforeReject
      let index = _.findIndex(
        filteredStatuses,
        (x) => x.text == statusBeforeReject
      )
      return _.slice(filteredStatuses, 0, index + 1).concat(
        this.currentTicketStatuses.find((x) => x.value === 'REJECTED')
      )
    },
    totalSteps() {
      return this.currentTicketStatuses.filter((x) =>
        x.workflowTicketTypes.includes(this.currentType.listQueryName)
      ).length
    },
    currentStep() {
      let step = 0
      this.currentTicketStatuses
        .filter((x) =>
          x.workflowTicketTypes.includes(this.currentType.listQueryName)
        )
        .forEach((status, index) => {
          if (_.toLower(status.value) === _.toLower(this.workflowTicket.status)) {
            step = index + 1
          }
        })
      return step
    },
    currentTypeName() {
      if (this.currentType.name === 'pcUserChangesWithOsRefresh') {
        return 'pcUserChanges'
      } else {
        return this.currentType.name
      }
    },
    isSecondToTheLastStep() {
      return this.currentStep === this.totalSteps - 2
    },
    validateWorkflowTicketForm() {
      return this.isSecondToTheLastStep
    },
    canApproveReject() {
      return _.includes(
        this.workflowTicket.personInCharge,
        this.user.employeeId
      )
    },
    ...mapGetters('user', ['user'])
  },
  methods: {
    approveOnSave(val) {
      if (val) {
        this.approve()
      }
    },
    rejectOnSave(val) {
      if (val) {
        this.reject()
      }
    },
    async save(action) {
      await this.$refs.workflowTicketFormRef[0].saveChangesAsync(action)
    },
    approve() {
      this.currentAction = 'Approve'
      this.confirmActionDialog = true
    },
    reject() {
      if (this.comment) {
        this.currentAction = 'Reject'
        this.confirmActionDialog = true
      } else {
        this.setAppSnackbar({
          text: 'Comment is mandatory to reject.',
          color: 'warning',
          timeout: 5000
        })
      }
    },
    confirmAction() {
      this.confirmActionDialog = false
      this.sendRawsRequest(this.currentAction)
    },
    sendRawsRequest(action) {
      let actionPastTense = null
      if (action === 'Approve') {
        actionPastTense = 'approved'
      } else if (action === 'Reject') {
        actionPastTense = 'rejected'
      } else {
        return
      }

      this.currentAction = ''
      this.loading = true

      let input = {
        name: this.workflowTicket.name,
        action,
        comment: this.comment,
        workflowTicketTypeId: this.currentType.rawsId,
        isForCompletion: this.isSecondToTheLastStep
      }

      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
            mutation processWorkflowTicket($input: ProcessWorkflowTicketInput!) {
              processWorkflowTicket(input: $input) {
                success
                message
                workflowTicketName
                workflowTicketStatus
              }
            }
          `,
          variables: { input }
        })
        .then(({ data }) => {
          if (data.processWorkflowTicket.success) {
            this.setAppSnackbar({
              text: `Workflow Ticket ${this.workflowTicket.name} ${actionPastTense}`,
              color: 'success'
            })
            this.workflowTicket.status = this.currentTicketStatuses.find(
              (x) => x.text.toLowerCase() === data.processWorkflowTicket.workflowTicketStatus.toLowerCase()
            ).value
          } else {
            this.setAppSnackbar({
              text: `Failed to ${action} ${this.workflowTicket.name}. ${data.processWorkflowTicket.message}`,
              color: 'error'
            })
          }
        })
        .catch((error) => {
          this.setAppSnackbar({
            text: `Failed to ${action} ${this.workflowTicket.name}. ${error}`,
            color: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateWorkflowTicket(workflowTicket) {
      this.$emit('updateWorkflowTicket', workflowTicket)
    },
    updateIsFormValid(val) {
      this.isFormValid = val
    },
    ...mapMutations(['setAppSnackbar'])
  }
}
